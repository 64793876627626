import React, { useState } from 'react'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus';
import * as styles from "./CommentTile.module.css"
import Button from '../../buttons/PrimaryButton/PrimaryButton'


export default function CommentTile({config}) {

    const [showComment, setShowComment] = useState(false);

    return (
        <>
            {       
                showComment ?
                <>
                    <br/>
                    <Disqus {...config}/>
                </> :
                <div className={styles.show_comment_btn_area}>
                    <Button 
                        className={styles.show_comment_btn} 
                        onClick={()=>setShowComment(true)}>
                            Show <CommentCount {...config} placeholder="Comments" />
                    </Button>
                </div>
            }
        </>
    )
}
