import React from 'react'
import * as styles from './PrimaryButton.module.css'

export default function PrimaryButton({children, onClick, className="", type}) {
    return (
        <button className={`${styles.button} ${className}`} onClick={onClick} type={type??'button'}>
            {children}
        </button>
    )
}
